<template>
  <d-container fluid>
    <pageTitle subtitle="Account Management"
               title="Rollen Overzicht"/>
    <d-row>
      <vCard :col="12" header="Rollen Overzicht">
      </vCard>
    </d-row>
  </d-container>
</template>

<script>
export default {
  name: 'userOverview',
  data() {
    return {
    }
  },
  created() {
  },
  methods: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
